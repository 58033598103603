
import { computed, defineComponent, reactive } from "vue";
import { list } from "./data";
import style from "./style.module.less";

const pageCount = Math.ceil(list.length / 6);

export default defineComponent({
  name: "Store",
  setup() {
    const state = reactive({ page: 0 });

    const items = computed(() => {
      const page =
        state.page > pageCount ? pageCount : state.page < 0 ? 0 : state.page;
      return list.slice(page * 6, (page + 1) * 6);
    });
    return () => (
      <div class={style.container}>
        <div class={style.title}>我是谜 线下店</div>
        <div class={style.subtitle}>加盟/从业/体验</div>
        <div class={style.company}>线下店由上海吾声网络科技有限公司运营</div>
        <div class={style.main}>
          <div class={style.list}>
            {items.value.map((x) => (
              <div class={style.item} key={x.club_id}>
                <div class={style.title}>{x.club_name}</div>
                <div class={style.location}>
                  <i />
                  {x.address}
                </div>
                <div class={style.tel}>
                  <i />
                  电话：{x.telephone}
                </div>

                <div
                  class={style.img}
                  style={{ backgroundImage: `url(${x.logo})` }}
                ></div>
              </div>
            ))}
          </div>
          <div class={style.pagination}>
            <div
              class={style.left}
              onClick={() => {
                if (state.page <= 0) return;
                state.page -= 1;
              }}
            ></div>
            {Array(pageCount)
              .fill(1)
              .map((x, i) => (
                <div
                  class={[
                    style.item,
                    i === state.page ? style.active : null,
                    i === pageCount - 1 ? style.end : null,
                  ]}
                  onClick={() => (state.page = i)}
                ></div>
              ))}

            <div
              class={style.right}
              onClick={() => {
                if (state.page >= pageCount - 1) return;
                state.page += 1;
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  },
});
