export const list = [
  {
    club_id: "2o0V4lR6cWy8fQ2wkWx",
    club_name: "DM侦探社",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1551943135388",
    telephone: 18207339333,
    remark: "",
    address: "希尔顿5栋B座1704",
    location: "27.8376600000,1.131500900000",
    city_code: 222,
    score: 9,
    created_time: "2019/3/7 15:20",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/7/30 14:56",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "7Da4UQnqj09p3HQ0sE1",
    club_name: "我是谜 赣州 万象城店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1595529704363",
    telephone: 18607977250,
    remark: "",
    address: "华润大厦B座 2206/2207",
    location: "25.822071,114.933229",
    city_code: 365,
    score: 10,
    created_time: "2020/7/24 2:41",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/7/24 2:43",
    created_user_name: "linshihao",
  },
  {
    club_id: "CRY9d2J74DzqjHe5w1q",
    club_name: "我是谜 莘庄店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1596599522789",
    telephone: 17269543274,
    remark: "",
    address: "上海市闵行区莘建东路198号10号楼12楼",
    location: "31.11907,121.39216",
    city_code: 289,
    score: 10,
    created_time: "2020/8/5 11:48",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/8/5 11:52",
    created_user_name: "linshihao",
  },
  {
    club_id: "Dh0K0GqPg2FBsybXbRZ",
    club_name: "山火桌游剧本工作室",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1542185911988",
    telephone: 13998688129,
    remark: "",
    address: "辽宁省大连市高新园区菁英汇二号楼二单元520",
    location: "38.8601300000,1.215305600000",
    city_code: 319,
    score: 9,
    created_time: "2018/11/14 16:58",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2021/1/18 16:51",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "ecU3cAJT31AvxCwKnM9",
    club_name: "我是谜实景剧本杀推理馆（大连高新店）",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1610960560074",
    telephone: 13354009600,
    remark: "",
    address: "高新园区黄浦路541号网络产业大厦408-1",
    location: "38.8625493,121.5303741",
    city_code: 167,
    score: 10,
    created_time: "2021/1/18 16:57",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2021/1/18 17:02",
    created_user_name: "linshihao",
  },
  {
    club_id: "ejEwQ9dmUuaPYqgEdqV",
    club_name: "南昌699店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1600084686862",
    telephone: 19100273376,
    remark: "",
    address: "青山湖区上海路699号699文创园B3栋-1F-4A",
    location: "28.666199,115.943283",
    city_code: 163,
    score: 10,
    created_time: "2020/9/14 20:00",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/9/14 20:00",
    created_user_name: "linshihao",
  },
  {
    club_id: "euOLmV6XE7Iown12HrJ",
    club_name: "我是谜 宜山路店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1594997332709",
    telephone: 17269543274,
    remark: "",
    address: "徐汇区宜山路705号科技大厦B703",
    location: "31.180141,121.422202,",
    city_code: 289,
    score: 10,
    created_time: "2020/7/17 22:48",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/7/17 22:51",
    created_user_name: "linshihao",
  },
  {
    club_id: "FbQIZWwcWbAuNJvjbas",
    club_name: "我是谜 静安店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1586316848270",
    telephone: 17269543274,
    remark: "",
    address: "武定路327弄申银发展大厦1号楼2202",
    location: "31.2408539300,121.4608823100",
    city_code: 289,
    score: 10,
    created_time: "2020/4/8 11:25",
    del_flag: 1,
    modifier_user_name: "Mvoicer",
    modify_time: "2020/4/8 11:34",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "iYEme6EBs5ymwnl6CWV",
    club_name: "我是谜二七广场店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1638844441710",
    telephone: 18595708110,
    remark: "",
    address: "华健商务大厦A座901",
    location: "34.76048,113.67238",
    city_code: 268,
    score: 10,
    created_time: "2021/12/7 10:38",
    del_flag: 1,
    modifier_user_name: "yunying3",
    modify_time: "2021/12/7 10:38",
    created_user_name: "yunying3",
  },
  {
    club_id: "jqTlYQIfqukps8JcJ2Z",
    club_name: "我是谜 青岛五四广场店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1603456559692",
    telephone: 15692322884,
    remark: "",
    address: "青岛市市南区香港中路18号福泰广场A座1407",
    location: "36.071057,120.391981",
    city_code: 236,
    score: 9,
    created_time: "2020/10/23 20:39",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/10/23 20:39",
    created_user_name: "linshihao",
  },
  {
    club_id: "KB9hKPORTGzk5la0AZo",
    club_name: "迷雾侦探社",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1563861597793",
    telephone: 18686031140,
    remark:
      "迷雾侦探社主营谋杀之谜剧本推理。上百种剧本供你选择。不一样的类型。不一样的刺激。数十种城市独家剧本更是精彩绝伦。迷雾侦探社欢迎我是谜的小伙伴莅临参观！",
    address: "赛罕区摩尔城B座901迷雾侦探社",
    location: "40.8372168563,1.117177316958",
    city_code: 321,
    score: 9,
    created_time: "2019/7/23 14:00",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/7/30 14:56",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "kkpW6iwEjSCyLoBgxj3",
    club_name: "我是谜 延安西路店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1586316857691",
    telephone: 17269543274,
    remark: "",
    address: "延安西路1228弄2号嘉利大厦15J",
    location: "31.2161735400,121.4343370100",
    city_code: 289,
    score: 10,
    created_time: "2020/4/8 11:27",
    del_flag: 1,
    modifier_user_name: "Mvoicer",
    modify_time: "2020/4/8 11:34",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "l0UrpvyPq6Ak7pBhon1",
    club_name: "我是谜实景推理馆-遵义店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1609909863474",
    telephone: 18275501885,
    remark: "",
    address: "贵州省遵义市汇川区珠海路万豪国际写字楼21楼",
    location: "27.726094,106.93655",
    city_code: 262,
    score: 10,
    created_time: "2021/1/6 13:12",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2021/1/18 17:04",
    created_user_name: "yunying3",
  },
  {
    club_id: "mPyhJ6GaRRyjZUx3nQ9",
    club_name: "我是谜剧本杀实景推理馆-南通店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1610960878003",
    telephone: 18706293432,
    remark: "",
    address: "江苏省南通市崇川区中南百货北区1110室",
    location: "31.974976,120.902166",
    city_code: 161,
    score: 10,
    created_time: "2021/1/6 12:47",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2021/1/18 17:07",
    created_user_name: "yunying3",
  },
  {
    club_id: "oBTN25UjGKqyIOFRElq",
    club_name: "AM侦探社",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1544614653568",
    telephone: 18390995995,
    remark: "",
    address: "湖南省长沙市雨花区融科东南海西海1栋1单元3301室",
    location: "28.1408230000,1.130180510000",
    city_code: 158,
    score: 9,
    created_time: "2018/12/12 19:38",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/7/30 14:56",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "Son2VTW5UPwmlg5zsQt",
    club_name: "全民大侦探(万塘店)",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1542096091415",
    telephone: 18217009923,
    remark: 15268205579,
    address: "华星路万塘汇广场2楼火星工厂前台旁",
    location: "30.2791320000,120.1256960000",
    city_code: 179,
    score: 10,
    created_time: "2018/11/13 16:01",
    del_flag: 1,
    modifier_user_name: "Mvoicer",
    modify_time: "2018/11/13 16:28",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "tpcnS5vlb0soqOCPQho",
    club_name: "迷雾侦探社",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1563860060990",
    telephone: 18686031140,
    remark: 18686031140,
    address: "内蒙古呼和浩特市赛罕区摩尔城B座901迷雾侦探社",
    location: "40.8372168563,111.7177316958",
    city_code: 321,
    score: 9,
    created_time: "2019/7/23 13:36",
    del_flag: 2,
    modifier_user_name: "Mvoicer",
    modify_time: "2019/7/23 13:36",
    created_user_name: "Mvoicer",
  },
  {
    club_id: "vM8zgJgsF6o4zWMLFbP",
    club_name: "我是谜 连云港 苏宁广场店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1606114482115",
    telephone: 15715121208,
    remark: "",
    address: "海州区通灌北路87号国际商务大厦A座1208",
    location: "34.603834,119.1822267",
    city_code: 347,
    score: 10,
    created_time: "2020/11/23 14:56",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/11/23 14:56",
    created_user_name: "linshihao",
  },
  {
    club_id: "wSz0prVe0CFgP4YYBHY",
    club_name: "我是谜 闸北店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1596599004756",
    telephone: 17269543274,
    remark: "",
    address: "中山北路198号申航大厦",
    location: "31.270609,121.477109",
    city_code: 289,
    score: 10,
    created_time: "2020/8/5 11:44",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2020/8/5 11:44",
    created_user_name: "linshihao",
  },
  {
    club_id: "x7hdmwKz3pw4H251NoE",
    club_name: "义乌之心店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1612345188677",
    telephone: 18066206020,
    remark: "",
    address: "义乌市稠城街道北门街七号四楼",
    location: "29.306221,120.073470",
    city_code: 333,
    score: 9,
    created_time: "2021/2/3 17:40",
    del_flag: 1,
    modifier_user_name: "linshihao",
    modify_time: "2021/2/3 17:56",
    created_user_name: "linshihao",
  },
  {
    club_id: "YixOS3QeWBvtcrfYk1o",
    club_name: "我是谜 秦皇岛环工店",
    logo: "https://woshimiimage.xiyacs.com/image/gameClub/1638782140428",
    telephone: 13303286585,
    remark: "",
    address: "谢李庄小区别墅1502号",
    location: "39.868715,119.463205",
    city_code: 148,
    score: 10,
    created_time: "2021/12/6 17:15",
    del_flag: 1,
    modifier_user_name: "yunying3",
    modify_time: "2021/12/6 17:51",
    created_user_name: "yunying3",
  },
];
